import React from 'react';

// External Components
import { Box, Paragraph, GridItem, Section } from '@thepuzzlers/pieces';

// Local Components
import {
  ImageContainer,
  GridSpacer,
  LeadPhoto,
  MarkedHeading
} from 'components';

// Animation
import { staggerImageGallery } from 'sections/animation';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Members = ({
  data: {
    description,
    ceo_name,
    ceo_position,
    ceo_image,
    ceo_image_small,
    project_head_name,
    project_head_position,
    project_head_image,
    business_head_name,
    business_head_position,
    business_head_image,
    estimation_head_name,
    estimation_head_position,
    estimation_head_image,
    timber_expert_name,
    timber_expert_position,
    timber_expert_image,
    management_member_name,
    management_member_position,
    management_member_image,
    sales_name,
    sales_position,
    sales_image,
    workspace,

    sales_and_marketing_position,
    sales_and_marketing_name,
    sales_and_marketing_image,
    accounting_head_position,
    accounting_head_name,
    accounting_head_image,
    sales_director_position,
    sales_director_name,
    sales_director_image,
    office_management_position,
    office_management_name,
    office_management_image,

    construction_management_name,
    construction_management_position,
    construction_management_image,
    architect_name,
    architect_position,
    architect_image,
    architect_two_name,
    architect_two_position,
    architect_two_image,
    procedurement_name,
    procedurement_position,
    procedurement_image
  }
}) => {
  React.useEffect(() => {
    // return staggerGalleryItemAnimation();
    return staggerImageGallery([
      '.member__row-one',
      '.member__row-two',
      '.member__row-three',
      '.member__row-four',
      '.member__row-five'
    ]);
  }, []);

  const memberList = [
    {
      name: project_head_name,
      position: project_head_position,
      image: project_head_image
    },

    {
      name: estimation_head_name,
      position: estimation_head_position,
      image: estimation_head_image
    },
    {
      name: ceo_name,
      position: ceo_position,
      image: ceo_image
    },
    {
      name: management_member_name,
      position: management_member_position,
      image: management_member_image
    },

    {
      name: construction_management_name,
      position: construction_management_position,
      image: construction_management_image
    },
    {
      name: architect_name,
      position: architect_position,
      image: architect_image
    },
    {
      name: architect_two_name,
      position: architect_two_position,
      image: architect_two_image
    },
    {
      name: procedurement_name,
      position: procedurement_position,
      image: procedurement_image
    },

    {
      name: sales_director_name,
      position: sales_director_position,
      image: sales_director_image
    },

    {
      name: sales_and_marketing_name,
      position: sales_and_marketing_position,
      image: sales_and_marketing_image
    },

    {
      name: accounting_head_name,
      position: accounting_head_position,
      image: accounting_head_image
    },
    {
      name: timber_expert_name,
      position: timber_expert_position,
      image: timber_expert_image
    }

    // {
    //   name: office_management_name,
    //   position: office_management_position,
    //   image: office_management_image
    // },

    // currently not displayed
    // {
    //   name: business_head_name,
    //   position: business_head_position,
    //   image: business_head_image
    // },

    // currently not displayed
    // { name: sales_name, position: sales_position, image: sales_image }
  ];

  return (
    // Markup
    <Section id="team__members">
      <Spacer position={0} />
      <Ceo name={ceo_name} position={ceo_position} image={ceo_image_small} />
      <Headline data={description} />
      <Spacer position={1} />

      <ThreePortraitInARow
        data={[memberList[0], memberList[1], memberList[2]]}
        itemClassName={'member__row-one'}
      />
      <Spacer position={2} />
      <ThreePortraitInARow
        data={[memberList[3], memberList[4], memberList[5]]}
        itemClassName={'member__row-two'}
      />
      <Spacer position={2} />
      <ThreePortraitInARow
        data={[memberList[6], memberList[7], memberList[8]]}
        itemClassName={'member__row-three'}
      />
      <Spacer position={2} />
      <ThreePortraitInARow
        data={[memberList[9], memberList[10], memberList[11]]}
        itemClassName={'member__row-four'}
      />
      {/* <Spacer position={2} />
      <ThreePortraitInARow
        data={[memberList[6], memberList[7], memberList[8]]}
        itemClassName={'member__row-three'}
      /> */}
      <Spacer position={2} />
      <OnePeopleAndALandscapeInARow
        workspaceImage={workspace}
        itemClassName={'member__row-five'}
      />
      {/* <Spacer position={3} />
      <ThreePortraitInARowV2
        data={[memberList[7], memberList[8]]}
        itemClassName={'member__row-four'}
      /> */}
    </Section>
  );
};

// Elements

const Ceo = ({ name, position, image: { image, alt } }) => (
  <LeadPhoto
    image={{
      src: image,
      alt: alt
    }}
    name={convertApiHtmlText(name.html)}
    position={position}
    sx={{ gridRow: 2 }}
  />
);

const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['2.8rem', '3.2rem', '3.4rem', '2.4rem', '2.8rem', '3.2rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 22',
        '1/span 22',
        '1/span 18',
        '1/span 16'
      ],
      gridRow: 3,
      lineHeight: 1.35,
      mt: ['3.3rem', '4rem', '3.7rem', '4rem', '3.4rem', '2.6rem']
    }}
  />
);

const ThreePortraitInARow = ({ data, itemClassName }) => {
  return (
    <RowWrapper
      // Animation Value
      className="team-member__ThreePortraitInARow"
      sx={{
        gridTemplateColumns: [
          '1fr',
          '1fr',
          '1fr',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)'
        ]
      }}>
      <TeamMember
        data={data[0]}
        // Animation value
        className={itemClassName || ''}
      />
      <TeamMember
        className={itemClassName || ''}
        data={data[1]}
        sx={{ justifySelf: 'end', mt: [0, 0, '2rem', 0, 0, 0] }}
      />
      <TeamMember
        className={itemClassName || ''}
        data={data[2]}
        sx={{ ml: [0, 0, '8.5%', 0, 0, 0], mt: [0, 0, '9.4rem', 0, 0, 0] }}
      />
    </RowWrapper>
  );
};

const OnePeopleAndALandscapeInARow = ({
  data,
  workspaceImage,
  itemClassName
}) => {
  const hasData = !!data;

  return (
    <>
      {hasData && (
        <TeamMember
          className={itemClassName || ''}
          data={data}
          sx={{
            gridColumn: [
              '4/13',
              '4/span 7',
              '9/span 13',
              '1/span 8',
              '1/span 8',
              '1/span 8'
            ]
          }}
        />
      )}
      <ImageContainer
        className={`members__workspace-image ${itemClassName || ''}`}
        src={workspaceImage.image}
        alt={workspaceImage.alt}
        sx={{
          width: '100%',
          height: ['39.5rem', '64rem', '53rem', '25.3rem', '37rem', '47rem'],
          mt: ['5.4rem', '5.4rem', '6.6rem', 0, 0, 0],
          ...(hasData
            ? {
                gridColumn: ['1/13', '1/13', '1/25', '9/25', '9/25', '9/25']
              }
            : {
                gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25']
              })
        }}
      />
    </>
  );
};

const ThreePortraitInARowV2 = ({ data, itemClassName }) => {
  const isHasTwoItems = data?.length === 2;

  return (
    <RowWrapper
      // Animation value
      sx={{
        gridTemplateColumns: isHasTwoItems
          ? ['1fr', '1fr', '1fr', '41% auto', '40.9% auto']
          : ['1fr', '1fr', '1fr', '41% auto auto', '40.9% auto auto']
      }}>
      <TeamMember className={itemClassName || ''} data={data[0]} isLastRow />
      {data[1] && (
        <TeamMember
          className={itemClassName || ''}
          data={data[1]}
          sx={{ justifySelf: 'end', mt: [0, 0, '2rem', 0, 0, 0] }}
          isLastRow
        />
      )}
      {data[2] && (
        <TeamMember
          className={itemClassName || ''}
          data={data[2]}
          sx={{ mt: [0, 0, '9.4rem', 0, 0, 0] }}
          isLastRow
        />
      )}
    </RowWrapper>
  );
};

// Reused Components

const RowWrapper = ({ children, sx, className }) => (
  <GridItem
    className={`members__row-wrapper ${className}`}
    sx={{
      display: 'grid',
      gap: ['5.4rem', '7.2rem', 0, '0.8rem', '0.8rem', '1.6rem'],
      ...sx
    }}>
    {children}
  </GridItem>
);

const TeamMember = ({
  data: { name, position, image },
  sx,
  className,
  isLastRow
}) => {
  // className of members__gallery-item is animation value
  return (
    <Box
      className={`members__team-member members__gallery-item ${className}`}
      sx={{
        height: [null, null, null, '100%', '100%', '100%'],
        ...sx
      }}>
      <ImageContainer
        src={image.image}
        alt={image.alt}
        sx={{
          width: ['23.9rem', '29.9rem', '37.8rem', '100%', '100%', '100%'],
          height: isLastRow
            ? ['27rem', '33.8rem', '42.7rem', '22rem', '32.4rem', '40.9rem']
            : ['27rem', '33.8rem', '42.7rem', '25.3rem', '37rem', '47rem']
        }}
      />
      <NameAndPosition name={name} position={position} />
    </Box>
  );
};

const NameAndPosition = ({ name, position }) => (
  <Box
    className="team-member__name-and-position"
    sx={{
      mt: ['2.4rem', '3rem', '3.8rem', '2.4rem', '1.2rem', '1.2rem']
    }}>
    <MarkedHeading
      as="h3"
      data={convertApiHtmlText(name.html)}
      sx={{
        fontSize: ['1.8rem', '1.8rem', '2.2rem', '1.6rem', '1.8rem', '2rem'],
        lineHeight: 1.25
      }}
    />
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        fontSize: ['1.4rem', '1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem'],
        lineHeight: 1.15,
        mt: '0.6rem'
      }}>
      {position}
    </Paragraph>
  </Box>
);

const Spacer = ({ position }) => {
  const styles = [
    {
      height: ['13.8rem', '12.9rem', '17.3rem', '13rem', '19.5rem', '13.3rem']
    },
    {
      height: ['20.5rem', '10rem', '7.2rem', '8.7rem', '19.5rem', '18.4rem']
    },
    {
      height: ['5.4rem', '7.2rem', '4.7rem', '7rem', '5.4rem', '4.1rem']
    },
    {
      height: ['5.6rem', '6.8rem', '10.7rem', '7rem', '5.4rem', '4.1rem']
    }
  ];

  return <GridSpacer sx={{ ...styles[position] }} />;
};
