import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useTeamPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: teamPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
        }
        belief {
          video {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
              alt
            }
            noise {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
              alt
            }
            text
          }
        }
      }
      de: deTeamPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
        }
        belief {
          video {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
              alt
            }
            noise {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
              alt
            }
            text
          }
        }
      }
    }
  `);
  return data[locale];
};
