import React from 'react';

// External Components
import { Box, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import {
  HighlightedHeading,
  GridSpacer,
  Year,
  TealNoiseSection
} from 'components';

// Animations
import { scaleYear, revealDescription } from './animations';

// Helper function
export const Header = ({ staticData: { headline }, data: { description } }) => {
  return (
    // Markup
    <ContentWrapper>
      <Headline data={headline} />
      <Description data={description} />
      <BottomSpacer />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <TealNoiseSection
    id="team__header"
    // Animation values
    initial="initial"
    animate="animate">
    {children}
  </TealNoiseSection>
);

const Headline = ({ data }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '2/ span 22',
          '4/ span 17',
          '5 / span 16',
          '5 / span 16'
        ],
        mt: ['18rem', '28.4rem', '36.5rem', '21rem', '29.4rem', '32.5rem'],
        position: 'relative'
      }}>
      <Year
        // Animation value
        variants={scaleYear}
        sx={{
          position: 'absolute',
          width: [
            '44.9rem',
            '63.7rem',
            '82.7rem',
            '51.6rem',
            '62.1rem',
            '77.6rem'
          ],
          top: [
            '-7.1rem',
            '-7.5rem',
            '-10.2rem',
            '-10.9rem',
            '-11.9rem',
            '-16.8rem'
          ],
          left: ['-6.5rem', '-6rem', '-9rem', 0, '2.2rem', '3.6rem'],
          zIndex: 0,
          color: 'darkTeal'
        }}
      />
      <Heading
        as="h1"
        sx={{
          fontFamily: 'primary.normal',
          fontSize: ['4.8rem', '6rem', '6.8rem', '3.8rem', '5.2rem', '6.4rem'],
          lineHeight: 1.15,
          color: 'background',
          textTransform: 'uppercase',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: 0
        }}>
        <Heading
          as="span"
          sx={{
            color: 'currentColor'
          }}>
          {data[0]}
        </Heading>
        <HighlightedHeading
          as="span"
          data={data[1]}
          sx={{
            ml: [null, null, null, '5.8rem', '8.6rem', '10.8rem'],
            mt: [null, null, null, '0.8rem', 0, 0],
            '& > span': {
              animation: '0.8s ease-out 0.8s lightToLimeText',
              animationFillMode: 'forwards',
              fontFamily: 'primary.italic'
            }
          }}
        />
      </Heading>
    </Box>
  );
};

const Description = ({ data }) => {
  return (
    <Paragraph
      // Animation value
      variants={revealDescription}
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.35,
        fontSize: ['2.8rem', '3.2rem', '3.4rem', '2.4rem', '2.8rem', '3.2rem'],
        color: 'background',
        gridColumn: [
          '1/13',
          '1/13',
          '2 / span 22',
          '2 / span 22',
          '3 / span 20',
          '3 / span 20'
        ],
        textAlign: 'center',
        mt: ['12.4rem', '23.5rem', '27.5rem', '16rem', '23.7rem', '18rem'],
        px: [0, 0, 0, 0, '2rem', 0]
      }}>
      {data}
    </Paragraph>
  );
};

const BottomSpacer = () => {
  return (
    <GridSpacer
      height={[
        '17.7rem',
        '18.1rem',
        '23.3rem',
        '14.5rem',
        '17.2rem',
        '22.4rem'
      ]}
    />
  );
};
