import React from 'react';

// External Components
import { Paragraph, Section, Box } from '@thepuzzlers/pieces';

// Local Components
import {
  ImageContainer,
  GridSpacer,
  MarkedHeading,
  AspectRatioImageContainer
} from 'components';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Animations
// import { videoSlide } from './animations';

export const Belief = ({ staticData: { video }, data: { headline } }) => {
  // React.useEffect(() => {
  //   // const killSlide = videoSlide();

  //   return killSlide;
  // }, []);

  return (
    <Section id="team__belief">
      <Spacer position={0} />
      <Headline data={headline} />
      {/* <Description data={description} /> */}
      <TeamVideoPlayer data={video} />
      <Spacer position={1} />
    </Section>
  );
};

const Headline = ({ data }) => {
  return (
    <MarkedHeading
      as="h2"
      data={convertApiHtmlText(data.html)}
      sx={{
        lineHeight: 1.15,
        fontSize: ['3.2rem', '3.6rem', '4.4rem', '3.2rem', '3.8rem', '4.8rem'],
        gridColumn: [
          '1/13',
          '1/13',
          '2/ span 22',
          '1/ span 13',
          '3/ span 10',
          '3/ span 10'
        ]
      }}
    />
  );
};

const Description = ({ data }) => {
  return (
    <Paragraph
      className="team__belief__description"
      sx={{
        lineHeight: 1.5,
        fontFamily: 'primary.normal',
        fontSize: ['1.7rem', '2rem', '2rem', '1.6rem', '1.7rem', '1.8rem'],
        gridColumn: [
          '1/13',
          '1/13',
          '2 / span 18',
          '15/25',
          '14 / span 9',
          '14 / span 9'
        ],
        mt: ['4.7rem', '4.8rem', '4.8rem', 0, 0, 0]
      }}>
      {data}
    </Paragraph>
  );
};

const TeamVideoPlayer = ({ data }) => {
  return (
    <AspectRatioImageContainer
      className="play-video-block__image"
      {...data.image}
      aspectRatios={[1 / 0.85, 1 / 0.85, 1 / 0.64, 1 / 0.5, 1 / 0.5, 1 / 0.5]}
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '3/span 20', '3/ span 20'],
        mt: ['5.6rem', '4.8rem', '10rem', '7.2rem', '6.4rem', '7.7rem']
      }}
    />

    // <VideoPlayer
    //   videoCode="VIMEO_EMBED_CODE_HERE"
    //   className="team__belief__video-player"
    //   data={data}
    //   sx={{
    //     gridColumn: ['1/13', '1/13', '1/25', '1/25', '3/span 20', '3/ span 20'],
    //     mt: ['5.6rem', '4.8rem', '10rem', '7.2rem', '6.4rem', '7.7rem']
    //   }}
    // />
  );
};

const Spacer = ({ position }) => {
  const heights = [
    ['21.7rem', '16.5rem', '31.2rem', '20rem', '33.9rem', '40rem'],
    ['19.7rem', '25.2rem', '32.8rem', '23.9rem', '25.2rem', '37.8rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
