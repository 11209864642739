const transitionDefault = {
  type: 'tween',
  ease: 'easeOut'
};

export const scaleYear = {
  initial: {
    scale: 0.8,
    opacity: 0
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      ...transitionDefault,
      duration: 1
    }
  }
};

export const revealDescription = {
  initial: {
    opacity: 0,
    y: 80
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ...transitionDefault,
      duration: 0.8,
      delay: 1.4
    }
  }
};
