import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { useTeamPageData, Header, About, Members, Belief } from 'sections/team';

// External Data
import { useKisAboutUsPageData } from 'graphqlHooks';

const Team = () => {
  const { seo, header, belief } = useTeamPageData();

  const {
    header: cmsHeader,
    about: cmsAbout,
    members: cmsMembers,
    belief: cmsBelief
  } = useKisAboutUsPageData();

  console.log('this is the membres', cmsMembers);

  return (
    <>
      <SEO {...seo} />
      <Header staticData={header} data={cmsHeader} />
      <About data={cmsAbout} />
      <Members data={cmsMembers} />
      <Belief staticData={belief} data={cmsBelief} />
    </>
  );
};

export default injectIntl(Team);
