import React from 'react';

// Local Components
import {
  TealNoiseSection,
  HighlightedHeading,
  GridSpacer,
  TextBlockWrapper
} from 'components';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const About = ({
  data: { headline, text_block_one, text_block_two }
}) => {
  const descriptions = [{ text: text_block_one }, { text: text_block_two }];

  return (
    <TealNoiseSection id="team__about">
      <Headline data={headline} />
      <DescriptionGroup data={descriptions} />
      <BottomSpacer />
    </TealNoiseSection>
  );
};

const Headline = ({ data }) => {
  return (
    <HighlightedHeading
      as="h2"
      data={convertApiHtmlText(data.html)}
      sx={{
        lineHeight: 1.5,
        fontSize: ['2.8rem', '3.2rem', '3.4rem', '2.4rem', '2.8rem', '3.2rem'],
        color: 'background',
        gridColumn: [
          '1/13',
          '1/13',
          '2/span 22',
          '2/span 22',
          '6/span 17',
          '7/span 15'
        ]
      }}
    />
  );
};

const DescriptionGroup = ({ data }) => {
  return (
    <TextBlockWrapper
      className="team__about__text-blocks"
      data={data}
      lightText
      sx={{
        gap: ['3.2rem', '4.8rem', '6.4rem', '6.7rem', '5rem', '7rem'],
        gridColumn: [
          '1/13',
          '1/span 10',
          '2/span 17',
          '2/span 22',
          '6/span 17',
          '7/span 15'
        ],
        mt: ['5.6rem', '7.2rem', '7rem', '4rem', '5.8rem', '7.2rem']
      }}
      textSx={{
        fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.5rem', '1.5rem', '1.6rem']
      }}
    />
  );
};

const BottomSpacer = () => (
  <GridSpacer
    height={['30rem', '27.1rem', '21.9rem', '11.3rem', '20.6rem', '21.8rem']}
  />
);
